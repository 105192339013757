import React from "react";
import type { TextFieldProps as AriaTextFieldProps, ValidationResult, TextFieldRenderProps } from "react-aria-components";
import { TextField as AriaTextFieldComponent } from "react-aria-components";
import { Description, FieldError, Input, Label } from "../Field";
import { composeTailwindRenderProps, focusRing } from "@/edges/utils";
import { Icon } from "../Icon/Icon";
const sizeStylesObject = {
  sm: "min-h-[theme(controlElementSize.sm)] max-h-[theme(controlElementSize.sm)] text-controlElementSm",
  md: "min-h-[theme(controlElementSize.md)] max-h-[theme(controlElementSize.md)] text-controlElementMd",
  lg: "min-h-[theme(controlElementSize.lg)] max-h-[theme(controlElementSize.lg)] text-controlElementLg",
  xl: "min-h-[theme(controlElementSize.xl)] max-h-[theme(controlElementSize.xl)] text-controlElementXl"
};
const getInputStyles = (props: {
  isInvalid?: boolean;
  isDisabled?: boolean;
  transparent?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  showSearchIcon?: boolean;
}) => {
  const baseStyles = `${focusRing(true)} w-full rounded-sm placeholder:text-text-placeholder`;
  const sizeStyles = props.size ? sizeStylesObject[props.size] : sizeStylesObject["md"];
  const transparentStyles = props.transparent ? "bg-transparent" : "";
  const paddingStyles = props.showSearchIcon ? "pl-8" : "";
  const styles = `${baseStyles} ${sizeStyles} ${transparentStyles} ${paddingStyles}`.trim();
  return styles;
};
export interface TextFieldProps extends AriaTextFieldProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  placeholder?: string;
  size?: "sm" | "md" | "lg" | "xl";
  tooltip?: string;
  isRequired?: boolean;
  transparent?: boolean;
  showSearchIcon?: boolean;
}
export function TextField({
  label,
  description,
  errorMessage,
  size = "md",
  tooltip,
  isRequired,
  transparent,
  showSearchIcon,
  ...props
}: TextFieldProps) {
  return <AriaTextFieldComponent
  // @ts-expect-error To be handled in another PR. We have an issue with the required prop from FormDialog
  isRequired={props.required ? undefined : isRequired} {...props} className={composeTailwindRenderProps(props.className, "group flex flex-col")} data-sentry-element="AriaTextFieldComponent" data-sentry-component="TextField" data-sentry-source-file="TextField.tsx">
      {({
      isDisabled,
      isInvalid
    }: TextFieldRenderProps) => <div className="flex flex-col">
          {label && <Label size={size} tooltip={tooltip} isRequired={isRequired}>
              {label}
            </Label>}
          <div className="relative w-full">
            {showSearchIcon && <div className="pointer-events-none absolute left-2 top-1/2 -translate-y-1/2 transform">
                <Icon name="MagnifyingGlass" className="h-4 w-4 text-text-muted" />
              </div>}
            <Input textSize={size} className={getInputStyles({
          isInvalid,
          isDisabled,
          size,
          transparent,
          showSearchIcon
        })} />
          </div>
          {description && <Description size={size} className="mt-3">
              {description}
            </Description>}
          <FieldError size={size} className="mt-3 text-feedback-error-text">
            {errorMessage}
          </FieldError>
        </div>}
    </AriaTextFieldComponent>;
}