"use client";

import React, { useRef, useEffect } from "react";
import { Logo } from "@/edges/ui/Logo";
import { Button } from "@/edges/ui/Button";
import { Icon } from "@/edges/ui/Icon";
import { SideNavLink } from "@/components/SideNavLink";
import { useDashboardStorage, DASHBOARD_STORAGE_KEYS } from "@/hooks/useDashboardStorage";

// Replace tv() with a regular function to construct classNames
const getSideNavClassNames = ({
  stateOpen,
  stateCollapsed
}: {
  stateOpen: "open" | "closed";
  stateCollapsed: "collapsed" | "expanded";
}) => {
  const baseClasses = "flex h-full flex-col justify-between border-r border-b bg-background-surface transition  top-0 left-0 z-20";
  const openClasses = {
    open: "translate-x-0 w-[80vw]",
    closed: "translate-x-[-80vw] md:translate-x-0"
  };
  const collapsedClasses = {
    collapsed: "md:w-[70px] md:min-w-[70px] md:max-w-[70px]",
    expanded: "md:w-64 md:min-w-64 md:max-w-64"
  };
  return `${baseClasses} ${openClasses[stateOpen]} ${collapsedClasses[stateCollapsed]}`;
};

// Replace the tv VariantProps type with a simple type
export type sideNavVariants = {
  stateOpen?: "open" | "closed";
  stateCollapsed?: "collapsed" | "expanded";
};

// Rest of the component remains exactly the same
export interface SideNavProps extends sideNavVariants {
  links: {
    iconName: string;
    label: string;
    href: string;
  }[];
  selectedLink?: string;
  className?: string;
  menuOpen: boolean;
  toggleMenu: () => void;
  workspaceSwitcher: React.ReactNode;
  isDarkThemeEnabled: boolean;
}
export function SideNav({
  links,
  selectedLink,
  menuOpen,
  toggleMenu,
  workspaceSwitcher,
  isDarkThemeEnabled
}: SideNavProps) {
  const sideNavRef = useRef<HTMLDivElement>(null);

  // Use the useDashboardStorage hook to manage the collapsed state
  const {
    value: collapsed,
    setValue: setCollapsed
  } = useDashboardStorage({
    key: DASHBOARD_STORAGE_KEYS.SIDENAV_COLLAPSED,
    // Updated key
    initialValue: false // Default value set to false
  });
  const toggleCollapse = () => {
    setCollapsed(prev => !prev);
  };
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (sideNavRef.current && !sideNavRef.current.contains(event.target as Node) && menuOpen) {
        toggleMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen, toggleMenu]);
  return <div ref={sideNavRef} data-testid="main-navigation" aria-label="Main navigation" role="navigation" className={getSideNavClassNames({
    stateOpen: menuOpen ? "open" : "closed",
    stateCollapsed: collapsed ? "collapsed" : "expanded"
  })} data-sentry-component="SideNav" data-sentry-source-file="SideNav.tsx">
      <div className="flex h-screen grow flex-col overflow-y-auto">
        <div className={`flex min-h-[60px] items-center justify-between border-b px-${collapsed ? "1" : "6"}`}>
          <Logo className={`relative bottom-[2px] ${collapsed ? "md:hidden" : ""}`} color={isDarkThemeEnabled ? "white" : "dark"} width={100} height={30} withWordmark data-sentry-element="Logo" data-sentry-source-file="SideNav.tsx" />
          <Button className={`hidden md:block ${collapsed ? "md:scale-x-[-1] md:transform" : ""}`} variant="icon" onPress={toggleCollapse} data-sentry-element="Button" data-sentry-source-file="SideNav.tsx">
            <Icon name="List" size={24} data-sentry-element="Icon" data-sentry-source-file="SideNav.tsx" />
          </Button>
          <Button className="md:hidden" variant="icon" onPress={toggleMenu} data-sentry-element="Button" data-sentry-source-file="SideNav.tsx">
            <Icon name="X" size={24} data-sentry-element="Icon" data-sentry-source-file="SideNav.tsx" />
          </Button>
        </div>
        <div className="flex grow flex-col justify-between">
          {!collapsed && <div className="border-t md:hidden">{workspaceSwitcher}</div>}
          <div className="flex flex-col pb-20 md:pb-0">
            {links?.map(link => <SideNavLink href={link.href} isActive={selectedLink ? selectedLink?.includes(link.href) : false} key={link.label} iconName={link.iconName} showLabel={!collapsed}>
                {link.label}
              </SideNavLink>)}
          </div>
          {!collapsed && <div className="hidden border-t md:block">{workspaceSwitcher}</div>}
        </div>
      </div>
    </div>;
}