"use client";

import React from "react";

// todo: reorg types for this and get rid of supergraph types in edges
import { Workspace } from "@/@codegen/supergraph";
import { Modal } from "@/edges/ui/Modal";
import { Dialog } from "@/edges/ui/Dialog";
import { Button } from "@/edges/ui/Button";
import { AddWorkspaceForm } from "./components/AddWorkspaceForm";
import { ModalListWithAddButton } from "./components/ModalListWithAddButton";
import { useTranslations } from "next-intl";
export interface WorkspaceSwitcherProps {
  workspaces: Workspace[];
  currentWorkspace: Workspace;
  canAddWorkspace?: boolean;
  onWorkspaceChange: (workspaceOrId: string | Workspace) => void;
}
export function WorkspaceSwitcher({
  workspaces,
  currentWorkspace,
  canAddWorkspace = false,
  onWorkspaceChange
}: WorkspaceSwitcherProps) {
  const t = useTranslations("workspace");
  const [isSelectWorkspaceModalOpen, setIsSelectWorkspaceModalOpen] = React.useState(false);
  const openSelectWorkspaceModal = () => setIsSelectWorkspaceModalOpen(true);
  const closeSelectWorkspaceModal = () => setIsSelectWorkspaceModalOpen(false);
  const [isAddWorkspaceModalOpen, setIsAddWorkspaceModalOpen] = React.useState(false);
  const closeAddWorkspaceModal = () => setIsAddWorkspaceModalOpen(false);
  const openAddWorkspaceModal = () => {
    setIsAddWorkspaceModalOpen(true);
    closeSelectWorkspaceModal();
  };
  const handleWorkspaceChange = (workspace: Workspace) => {
    onWorkspaceChange(workspace);
    closeSelectWorkspaceModal();
  };
  return <>
      <div className="flex h-[72px] w-full pb-1" onClick={openSelectWorkspaceModal}>
        <div className="flex h-full w-full flex-col items-start justify-center pl-5">
          <div className="text-sm text-text-muted">{t("title")}</div>
          <div className="font-semibold text-text-body">
            {currentWorkspace?.name}
          </div>
        </div>
        <div className="flex h-full w-10 items-center justify-center pr-4">
          <Button variant="icon" onPress={openSelectWorkspaceModal} icon="CaretUp" data-sentry-element="Button" data-sentry-source-file="WorkspaceSwitcher.tsx" />
        </div>
      </div>
      <ModalListWithAddButton<Workspace> title={t("switch")} isOpen={isSelectWorkspaceModalOpen} onClose={closeSelectWorkspaceModal} items={workspaces} selectedItemId={currentWorkspace.id} onSelectItem={handleWorkspaceChange} addButtonText={t("add")} addButtonOnPress={openAddWorkspaceModal} data-sentry-element="ModalListWithAddButton" data-sentry-source-file="WorkspaceSwitcher.tsx" />

      <Modal isDismissable isOpen={isAddWorkspaceModalOpen} onOpenChange={closeAddWorkspaceModal} data-sentry-element="Modal" data-sentry-source-file="WorkspaceSwitcher.tsx">
        <Dialog style={{
        padding: "8px"
      }} data-sentry-element="Dialog" data-sentry-source-file="WorkspaceSwitcher.tsx">
          <AddWorkspaceForm onSuccess={closeAddWorkspaceModal} onCanceled={closeAddWorkspaceModal} data-sentry-element="AddWorkspaceForm" data-sentry-source-file="WorkspaceSwitcher.tsx" />
        </Dialog>
      </Modal>
    </>;
}