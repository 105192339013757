"use client";

import React from "react";
import { Icon } from "@/edges/ui/Icon";
import Link from "next/link";
export interface SideNavLinkProps {
  className?: string;
  children: React.ReactNode;
  iconName: string;
  href: string;
  isActive?: boolean;
  showLabel?: boolean;
}

// Helper function to generate className string
const getSideNavLinkClasses = ({
  isActive = false,
  className = ""
}: {
  isActive: boolean;
  className?: string;
}) => {
  const baseClasses = "flex w-full items-center gap-3 no-underline cursor-pointer pl-6 h-11 hover:text-action-primary duration-200";
  const stateClasses = isActive ? "bg-background-menu-active text-action-primary font-semibold hover:cursor-pointer hover:text-inherit border-l-2 border-action-primary" : "hover:cursor-pointer";
  return `${baseClasses} ${stateClasses} ${className}`.trim();
};
export function SideNavLink({
  children,
  iconName,
  isActive = false,
  showLabel = true,
  className,
  href = "/"
}: SideNavLinkProps) {
  return <Link href={href} data-sentry-element="Link" data-sentry-component="SideNavLink" data-sentry-source-file="SideNavLink.tsx">
      <div className={getSideNavLinkClasses({
      isActive,
      className
    })}>
        <Icon name={iconName} size={16} data-sentry-element="Icon" data-sentry-source-file="SideNavLink.tsx" />
        <div className={`flex pt-[1px] ${showLabel ? "" : "md:hidden"}`}>
          {children}
        </div>
      </div>
    </Link>;
}