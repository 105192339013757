"use client";

import { setLoggedUser, getLoggedUser } from "@/state/user/useLoggedUser";
import { Role } from "@/@codegen/graphql";
import { Invitation, LoggedUser, Organization } from "@/@codegen/supergraph";

import { set } from "lodash/fp";
import { get, isEqual, isString } from "lodash";
import {
  temperatureStringToSymbol,
  TemperatureUnitString,
} from "@/utils/string";

function isAdminOfOrganization(user: LoggedUser) {
  return function (organization: string | Organization) {
    const organizationId = isString(organization)
      ? organization
      : organization?.id;
    return user.memberships?.some((membership) => {
      return (
        membership?.organization?.id === organizationId &&
        membership?.role === Role.Admin
      );
    });
  };
}

function parseTemperatureFromFahrenheitToPreferredUnitWithSymbol(
  user: LoggedUser,
) {
  return function (fahrenheitTemperature: number) {
    const preferredTemperatureUnit = getPreferredTemperatureUnit(user)();
    const temperature = parseTemperatureFromFahrenheitToPreferredUnit(user)(
      fahrenheitTemperature,
    );

    return preferredTemperatureUnit === "FAHRENHEIT"
      ? temperature.toString().concat("°F")
      : temperature.toString().concat("°C");
  };
}

function parseTemperatureFromFahrenheitToPreferredUnit(user: LoggedUser) {
  return function (fahrenheitTemperature: number) {
    const preferredTemperatureUnit = getPreferredTemperatureUnit(user)();
    return preferredTemperatureUnit === "FAHRENHEIT"
      ? fahrenheitTemperature
      : parseFloat((((fahrenheitTemperature - 32) * 5) / 9).toFixed(0));
  };
}

function getPreferredTemperatureUnit(user: LoggedUser) {
  return function ({ asSymbol = false }: { asSymbol?: boolean } = {}) {
    const unit =
      user.profile.preferredTemperatureUnit ||
      user.authenticatedOrganization?.preferredTemperatureUnit;
    if (!asSymbol) {
      return unit;
    }
    return temperatureStringToSymbol(unit as TemperatureUnitString);
  };
}

function setAsVerified(user: LoggedUser, setLoggedUser: any) {
  return function () {
    setLoggedUser(set<LoggedUser>("email.verified", true, user));
  };
}

function getTrackingUserData(user: LoggedUser) {
  return function () {
    const selectedOrganization = user.authenticatedOrganization;

    const membershipRole = user.memberships?.find(
      (membership) => membership?.organization?.id === selectedOrganization?.id,
    )?.role;

    return {
      id: user.id,
      userId: user.id,
      email: user.email?.email,
      name: user.profile.fullName,
      firstName: user?.profile?.firstName || undefined,
      lastName: user?.profile?.lastName || undefined,
      organizationName: selectedOrganization?.name,
      organizationId: selectedOrganization?.id,
      isTextureEmployee: user.isTextureEmployee,
      membershipRole,
    };
  };
}

export function isInvitationForMe(user: LoggedUser) {
  return function (invitation: Partial<Invitation>) {
    return (
      invitation?.email &&
      user?.id &&
      isEqual(invitation?.email, user?.email?.email)
    );
  };
}

function getOrganizationById(user: LoggedUser) {
  return function (organizationId: string): Organization | null {
    const organization = user.memberships?.find(
      (membership) => membership?.organization?.id === organizationId,
    )?.organization;
    return organization || null;
  };
}

export function hasProductionOrganization(user: LoggedUser) {
  return function () {
    return user.memberships?.some(
      (membership) => membership?.organization?.personal === false,
    );
  };
}

function getFirstOrganization(user: LoggedUser) {
  return function () {
    return get(user, "memberships.0.organization");
  };
}

function isVerified(user: LoggedUser) {
  return function () {
    return user?.email?.verified === true;
  };
}

function isNotVerified(user: LoggedUser) {
  return function () {
    return isVerified(user)() === false;
  };
}
// We should maybe rename selectedOrganization to loggedInOrganization or authenticatedOrganization
function isSelectedOrganization(user: LoggedUser) {
  return function (organizationOrOrganizationId: Organization | string) {
    const id = isString(organizationOrOrganizationId)
      ? organizationOrOrganizationId
      : organizationOrOrganizationId?.id;

    return isEqual(id, user.authenticatedOrganization?.id);
  };
}

function isOrganizationInLoggedUserMemberships(user: LoggedUser) {
  return function (organizationOrOrganizationId: Organization | string) {
    const id = isString(organizationOrOrganizationId)
      ? organizationOrOrganizationId
      : organizationOrOrganizationId?.id;

    return user.memberships?.some(
      (membership) => membership?.organization?.id === id,
    );
  };
}

const useLoggedUserFunctions = () => {
  const performWithLoggedUserOrFalse = (
    fn: (user: LoggedUser, setLoggedUser?: any) => any,
  ) => {
    return (...props: any) => {
      const user = getLoggedUser();
      if (!user) return () => false;
      return fn(user, setLoggedUser)(...props);
    };
  };

  return {
    isVerified: performWithLoggedUserOrFalse(isVerified),
    isNotVerified: performWithLoggedUserOrFalse(isNotVerified),
    hasProductionOrganization: performWithLoggedUserOrFalse(
      hasProductionOrganization,
    ),
    setAsVerified: performWithLoggedUserOrFalse(setAsVerified),
    isAdminOfOrganization: performWithLoggedUserOrFalse(isAdminOfOrganization),
    isInvitationForMe: performWithLoggedUserOrFalse(isInvitationForMe),
    getTrackingUserData: performWithLoggedUserOrFalse(getTrackingUserData),

    getFirstOrganization: performWithLoggedUserOrFalse(getFirstOrganization),

    getOrganizationById: performWithLoggedUserOrFalse(getOrganizationById),
    isSelectedOrganization: performWithLoggedUserOrFalse(
      isSelectedOrganization,
    ),
    isOrganizationInLoggedUserMemberships: performWithLoggedUserOrFalse(
      isOrganizationInLoggedUserMemberships,
    ),

    parseTemperatureFromFahrenheitToPreferredUnitWithSymbol:
      performWithLoggedUserOrFalse(
        parseTemperatureFromFahrenheitToPreferredUnitWithSymbol,
      ),
    parseTemperatureFromFahrenheitToPreferredUnit: performWithLoggedUserOrFalse(
      parseTemperatureFromFahrenheitToPreferredUnit,
    ),
    getPreferredTemperatureUnit: performWithLoggedUserOrFalse(
      getPreferredTemperatureUnit,
    ),
  };
};

export default useLoggedUserFunctions;
