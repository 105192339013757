"use client";

import React from "react";
import Link from "next/link";
import { Button } from "@/edges/ui/Button";
import { Icon } from "@/edges/ui/Icon";
export interface TopNavProps {
  toggleMenu: () => void;
  className?: string;
  userMenu: React.ReactNode;
  helpLink: string;
  toggleTheme: () => void;
  showThemeToggle: boolean;
  isDarkThemeEnabled: boolean;
}
export function TopNav({
  toggleMenu,
  className,
  helpLink,
  userMenu,
  toggleTheme,
  showThemeToggle,
  isDarkThemeEnabled
}: TopNavProps) {
  const handleMenuToggle = () => {
    toggleMenu();
  };
  return <nav className={`absolute left-0 right-0 top-0 z-10 flex min-h-[60px] items-center justify-between border-b bg-background-surface dark:border-0 md:justify-end ${className && className}`} data-sentry-component="TopNav" data-sentry-source-file="TopNav.tsx">
      {/* Mobile */}
      <Button onPress={handleMenuToggle} className="h-full" variant="icon" data-sentry-element="Button" data-sentry-source-file="TopNav.tsx">
        <div className="flex h-full w-14 flex-col items-center justify-center border-r md:hidden">
          <Icon name="List" size={24} data-sentry-element="Icon" data-sentry-source-file="TopNav.tsx" />
        </div>
      </Button>
      {/* Desktop */}
      <div className="flex items-center gap-4 px-6">
        <Link href={helpLink || ""} passHref className="rounded p-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" aria-label="Help" data-sentry-element="Link" data-sentry-source-file="TopNav.tsx">
          <Icon name="Question" size={24} aria-hidden="true" data-sentry-element="Icon" data-sentry-source-file="TopNav.tsx" />
        </Link>
        {showThemeToggle && <Button variant="secondary" onPress={toggleTheme}>
            <Icon name={isDarkThemeEnabled ? "Sun" : "Moon"} size={24} aria-hidden="true" />
          </Button>}
        {userMenu}
      </div>
    </nav>;
}