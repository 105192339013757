import React from "react";

import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
import { useSelectedOrganization } from "@/state/user/useSelectedOrganization";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import { ROUTES } from "@/routes";
interface SidebarLink {
  iconName: string;
  label: string;
  href: string;
}

export const useSidebarLinks = () => {
  const isAppsEnabled = useFeatureIsOn("apps");
  const isLeadsEnabled = useFeatureIsOn("leads");
  const isCollectionsEnabled = useFeatureIsOn("collections");
  const isProgramsEnabled = useFeatureIsOn("programs");
  const isReportsEnabled = useFeatureIsOn("reports");

  const { selectedOrganization } = useSelectedOrganization();
  const { isAdminOfOrganization: isAdminRaw } = useLoggedUserFunctions();

  const isAdminOfOrganization = React.useMemo(
    () => isAdminRaw(selectedOrganization),
    [selectedOrganization],
  );

  const standardLinks: SidebarLink[] = [
    {
      iconName: "SquaresFour",
      label: "Home",
      href: ROUTES.private.home,
    },
    {
      iconName: "Users",
      label: "Customers",
      href: ROUTES.private.customers,
    },
    {
      iconName: "Buildings",
      label: "Sites",
      href: ROUTES.private.sites,
    },
    {
      iconName: "ShareNetwork",
      label: "Devices",
      href: ROUTES.private.devices,
    },
    {
      iconName: "PlugsConnected",
      label: "Sources",
      href: ROUTES.private.sources,
    },
  ];

  const appLink = {
    iconName: "AppWindow",
    label: "Apps",
    href: ROUTES.private.apps,
  };

  const leadsLink = {
    iconName: "UserPlus",
    label: "Leads",
    href: ROUTES.private.leads,
  };

  const programsLink = {
    iconName: "ClipboardText",
    label: "Programs",
    href: ROUTES.private.programs,
  };

  const collectionsLink = {
    iconName: "Stack",
    label: "Collections",
    href: ROUTES.private.collections,
  };

  const reportsLink = {
    iconName: "ChartLine",
    label: "Reports",
    href: ROUTES.private.reports,
  };

  const developerLink = {
    iconName: "BracketsCurly",
    label: "Developer",
    href: ROUTES.private.developer,
  };

  const settingsLink = {
    iconName: "GearSix",
    label: "Settings",
    href: ROUTES.private.settings,
  };

  const links = React.useMemo(() => {
    const links = [...standardLinks];
    if (isCollectionsEnabled) {
      links.push(collectionsLink);
    }
    if (isAppsEnabled) {
      links.push(appLink);
    }
    if (isLeadsEnabled) {
      links.push(leadsLink);
    }
    if (isProgramsEnabled) {
      links.push(programsLink);
    }
    if (isReportsEnabled) {
      links.push(reportsLink);
    }
    if (isAdminOfOrganization) {
      links.push(developerLink);
    }
    links.push(settingsLink);
    return links;
  }, [
    isAppsEnabled,
    isLeadsEnabled,
    isCollectionsEnabled,
    isProgramsEnabled,
    isReportsEnabled,
    isAdminOfOrganization,
  ]);

  return { links };
};
