"use client";

import Head from "next/head";
import { useState } from "react";
import { useNavigationService } from "@/services/navigationService";
import { useLoggedUser } from "@/state/user/useLoggedUser";
import { useSelectedWorkspace } from "@/state/user/useSelectedWorkspace";
import { useSelectedOrganization } from "@/state/user/useSelectedOrganization";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
import { UserMenu } from "@/edges/ui/UserMenu";
import { Workspace } from "@/edges/ui/WorkspaceSwitcher";
import { useSidebarLinks } from "@/common/navigation";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useColorMode } from "@/providers/ColorModeProvider";
import { SideNav } from "@/components/SideNav";
import { TopNav } from "@/components/TopNav";
import { WorkspaceSwitcher } from "@/components/WorkspaceSwitcher";
export default function DashboardLayout({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    links
  } = useSidebarLinks();
  const {
    isDarkTheme,
    toggleTheme
  } = useColorMode();
  const {
    loggedUser
  } = useLoggedUser();
  const {
    isRouteUnderIdFolder,
    getPrimaryRoute,
    navigateTo
  } = useNavigationService();
  const selectedLink = getPrimaryRoute();
  const {
    selectedWorkspace,
    setSelectedWorkspace
  } = useSelectedWorkspace();
  const handleWorkspaceChange = (workspaceOrId: string | Workspace) => {
    setSelectedWorkspace(typeof workspaceOrId === "string" ? {
      id: workspaceOrId
    } as Workspace : workspaceOrId);
    if (isRouteUnderIdFolder()) {
      navigateTo(getPrimaryRoute());
    }
  };
  const {
    selectedOrganization
  } = useSelectedOrganization();
  const {
    isAdminOfOrganization
  } = useLoggedUserFunctions();
  const isThemeToggleEnabled = useFeatureIsOn("theme-toggle");
  const [menuOpen, setMenuOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  if (!loggedUser || !selectedWorkspace) {
    return null;
  } else {
    return <>
        <Head>
          <link rel="preconnect" href="https://gateway.texture.energy" />
          <link rel="preconnect" href="https://cdn.filestackcontent.com" />
        </Head>
        <div data-testid="app-layout" className="relative flex h-screen overflow-hidden">
          {menuOpen && <div className="absolute bottom-0 left-0 right-0 top-0 bg-gray-500 opacity-50 transition-opacity" onClick={toggleMenu} role="button" tabIndex={0} />}
          <SideNav toggleMenu={toggleMenu} menuOpen={menuOpen} links={links} selectedLink={selectedLink} isDarkThemeEnabled={isDarkTheme} workspaceSwitcher={<WorkspaceSwitcher workspaces={selectedOrganization!.workspaces ?? []} currentWorkspace={selectedWorkspace} onWorkspaceChange={handleWorkspaceChange} canAddWorkspace={isAdminOfOrganization(selectedWorkspace.organizationId)} />} />
          <div className="absolute bottom-0 left-0 right-0 top-0 flex grow flex-col overflow-hidden md:static md:w-full">
            <TopNav toggleMenu={toggleMenu} userMenu={<UserMenu />} helpLink="https://docs.texturehq.com/docs/home/welcome" showThemeToggle={isThemeToggleEnabled} toggleTheme={toggleTheme} isDarkThemeEnabled={isDarkTheme} />
            <div className="flex h-full flex-col items-center overflow-y-auto bg-background-body pt-[60px]">
              {children}
            </div>
          </div>
        </div>
      </>;
  }
}