"use client";

import { useCallback, useEffect, useState } from "react";

// Type for storage keys to ensure type safety across the application
export const DASHBOARD_STORAGE_KEYS = {
  SIDENAV_COLLAPSED: "dashboard-sidenav-collapsed",
  COLLECTIONS: "dashboard-collections-data",
} as const;

type DashboardStorageKey =
  (typeof DASHBOARD_STORAGE_KEYS)[keyof typeof DASHBOARD_STORAGE_KEYS];

interface UseDashboardStorageOptions<T> {
  key: DashboardStorageKey;
  initialValue: T;
  // Optional callback when value changes
  onChange?: (value: T) => void;
  // Optional serializer/deserializer for complex objects
  serialize?: (value: T) => string;
  deserialize?: (value: string) => T;
}

export function useDashboardStorage<T>({
  key,
  initialValue,
  onChange,
  serialize = JSON.stringify,
  deserialize = JSON.parse,
}: UseDashboardStorageOptions<T>) {
  // State to hold the current value
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? deserialize(item) : initialValue;
    } catch (error) {
      console.error(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  });

  // Update localStorage when the value changes
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    try {
      window.localStorage.setItem(key, serialize(storedValue));
      onChange?.(storedValue);
    } catch (error) {
      console.error(`Error writing to localStorage key "${key}":`, error);
    }
  }, [key, storedValue, serialize, onChange]);

  // Memoized setValue function
  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        setStoredValue((prev) => {
          const valueToStore = value instanceof Function ? value(prev) : value;
          return valueToStore;
        });
      } catch (error) {
        console.error(
          `Error setting value for localStorage key "${key}":`,
          error,
        );
      }
    },
    [key],
  );

  // Memoized remove function
  const remove = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
      setStoredValue(initialValue);
    } catch (error) {
      console.error(`Error removing localStorage key "${key}":`, error);
    }
  }, [key, initialValue]);

  return {
    value: storedValue,
    setValue,
    remove,
    key,
  };
}

// Example usage:
// export function useThemePreference() {
//   return useDashboardStorage({
//     key: DASHBOARD_STORAGE_KEYS.THEME,
//     initialValue: "light",
//     onChange: (theme) => document.documentElement.classList.toggle("dark", theme === "dark"),
//   });
// }

// export function useTablePageSize() {
//   return useDashboardStorage({
//     key: DASHBOARD_STORAGE_KEYS.TABLE_PAGE_SIZE,
//     initialValue: 10,
//   });
// }

// export function useChartPreferences() {
//   return useDashboardStorage<{
//     showLegend: boolean;
//     defaultTimeRange: string;
//     colors: Record<string, string>;
//   }>({
//     key: DASHBOARD_STORAGE_KEYS.CHART_PREFERENCES,
//     initialValue: {
//       showLegend: true,
//       defaultTimeRange: "7d",
//       colors: {},
//     },
//   });
// }
